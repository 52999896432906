/*
*   Purpose: This module is a catch all for code snippets that end up being reused in multiple places throughout the code base
*   Note: The idea behind this module is to reduce overall deployment code size and improve code consistency by reusing code across many pages.
*       Not all pages use this library yet.
*/ 

const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
const { DateTime } = require("luxon");

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


var cleaners = {
    stripHTML(inputString){
        // Uses DOMParser, even IE Supports this solution
        // From: https://stackoverflow.com/a/47140708
        // Notably also strips whitespace from the begining of the input (untested)
        let doc = new DOMParser().parseFromString(inputString, 'text/html');
        return doc.body.textContent || "";
    }
}

var formatters = {
    timestampToDateTime(input, includeTime = false, includeSeconds = false, dateTimeSeparator = ' ', dateSeparator = '-', timeSeparator = ':'){
        // NOTE: DO NOT upgrade this to the much better and cleaner String.prototype.padStart() because IE doesn't support it. -- We can ignore this, we dont support IE officially.
        var date = new Date(input);
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var dt = date.getDate();
        var hr = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        if (hr < 10) {
            hr = '0' + hr;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }
        if(includeTime){
            if(includeSeconds){
                return `${year}${dateSeparator}${month}${dateSeparator}${dt}${dateTimeSeparator}${hr}${timeSeparator}${min}${timeSeparator}${sec}`;
            }else{
                return `${year}${dateSeparator}${month}${dateSeparator}${dt}${dateTimeSeparator}${hr}${timeSeparator}${min}`;
            }
        }else{
            return `${year}${dateSeparator}${month}${dateSeparator}${dt}`;
        }
    },
    capitalizeFirstLetter(str, returnOnFailure = ''){
        // Returns the string with first letter capitialized
        // If the input isnt a string, it returns an empty string
        if (typeof str !== 'string'){
            return returnOnFailure;
        }else{
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    snakeCaseToHumanReadable(str, titleCase = false){
        var parts = str.split("_");
        var formattedParts = parts.map((part)=>{
            if(titleCase){
                return this.capitalizeFirstLetter(part);
            }else{
                return part;
            }
        });
        return formattedParts.join(" ");
    },
    primaryTableLowerToSingular(str){
        if(str == "customers"){
            return "customer";
        }else if(str == "sites"){
            return "site";
        }else if(str == "connections"){
            return "connection";
        }else if(str == "devices"){
            return "device";
        }else if(str == "utilities"){
            return "utility";
        }else if(str == "technicians"){
            return "technician";
        }else{
            return "unknown";
        }
    },
    requestSizeToHumanReadableSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si 
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    },
    timestampToFuzzyTime(input, titleCase = false){
        try {
            var date = new Date(input);
            var current = new Date()
            var delta = current - date;
            var isPostfix = true;
            var postFix = "ago"
            var preFix = "in"
            if(delta < 0){
                isPostfix = false;
            }
            var fuzz = "";
            var seconds = Math.abs(delta / 1000);
            if(seconds > 60){
                // Graduate To Minutes
                var minutes = seconds / 60;
                if(minutes < 1.5){
                    fuzz = "about a minute";
                }else if(minutes < 45){
                    fuzz = `${Math.floor(minutes)} minutes`;
                }else if(minutes < 90){
                    fuzz = `an hour`;
                }else if(minutes < 1260){
                    fuzz = `${Math.round(minutes / 60)} hours`;
                }else if(minutes < 2100){
                    if(delta < 0){
                        // Its in the future
                        return caseSetup("tommorow");
                    }else{
                        // Its in the past
                        return caseSetup("yesterday");
                    }
                }else{
                    // Graduate To Days
                    var days = minutes / 1440;
                    if(days < 25){
                        fuzz = `${Math.round(days)} days`
                    }else if(days < 45){
                        preFix = "next";
                        fuzz = "month";
                        postFix = "last";
                    }else if(days < 365){
                        preFix = "next";
                        fuzz = "year";
                        postFix = "last";
                    }else if(days < 558){
                        fuzz = "more than a year";
                    }else if(days < 735){
                        fuzz = "about two years"
                    }else{
                        // Graduate To Years
                        var years = days / 365;
                        if(years < 50){
                            fuzz = `${Math.round(years)} years`
                        }else{
                            fuzz = `${Math.round(years / 10)} decades`
                        }
                    }
                }
            }else if(seconds > 30 ){
                fuzz = "less than a minute";
            }else{
                fuzz = "a few moments";
            }
            if(isPostfix){
                return caseSetup(`${fuzz} ${postFix}`);
            }else{
                return caseSetup(`${preFix} ${fuzz}`);
            }
            
        } catch (err) {
            console.log(err)
            return `Some Time Ago`;
        }

        function caseSetup(inp){
            if(titleCase){
                var str = inp.split(' ');
                var capped = []
                str.forEach((word)=>{
                    capped.push(word.charAt(0).toUpperCase() + word.slice(1));
                });
                return capped.join(' ');
            }else{
                return inp;
            }
        }
    }
}

var validate = {
    isUUID(input){
        if( input == null ){ return true; }
        // regex for valid uuid for RFC4122
        var uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
        if(input.match(uuidRegex) == null){
            return false;
        }else{
            return true;
        }
    },
    isEmail(input){
        return emailRegex.test(input);
    }
}

function isError401(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'status')){
            if(resp.status == 401){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function isError403(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'status')){
            if(resp.status == 403){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function isError404(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'status')){
            if(resp.status == 404){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function isError409(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'status')){
            if(resp.status == 409){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function isError500(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'status')){
            if(resp.status == 500){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function hasAPIResponseFormat(err){
    if(_.has(err, 'response')){
        var resp = err.response;
        if(_.has(resp, 'data')){
            if(_.has(resp.data, 'errors')){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

var createToast = (vue, toastTitle, toastBody = '', toastVariant = null, timer = 20)=>{
    vue.$bvToast.toast(toastBody, {
        variant: toastVariant,
        toaster: 'b-toaster-bottom-center',
        title: toastTitle,
        autoHideDelay: timer * 1000,
        appendToast: true
    })
}

var customInstance = {
    fileUpload(timeoutInMs){
        return axios.create({ timeout: timeoutInMs, headers: {'Content-Type': 'multipart/form-data'}, withCredentials: true, crossdomain: true });
    },
    timeoutLength(timeoutInMs){
        return axios.create({ timeout: timeoutInMs, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
    },
    timeoutAndBlob(timeoutInMs){
        return axios.create({ timeout: timeoutInMs, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true, responseType: 'blob' });
    },
    responseType:{
        blob(){
            return axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true, responseType: 'blob' });
        }
    },
    infiniteTimeWithCancel(abortcontroller){
        return axios.create({ headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true, signal: abortcontroller.signal });
    },
    infiniteTime(){
        return axios.create({ headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
    }
};

function setWindowTitle(title){
    document.title = `${title}`;
}

function updateWindowPath(vue, path){
    if(vue.$router.currentRoute.path != path){
        vue.$router.replace({ path: path })
    }
}

function updateWindowPathAndSetTitle(vue, path, title){
    setWindowTitle(title);
    updateWindowPath(vue, path);
}

function axiosErrorIsCancel(err){
    if(_.has(err, "message")){
        if(err.message === "canceled"){
            return true
        }
        return false;
    }else{
        return false;
    }
}


function apiRequestErrorHandling(err, vue, actionDesc, skipGeneralError = false, callOnGeneralError = null){
    if(isError401(err)){
        createToast(vue, 'Logged Out', 'Login Again', 'warning');
    }else if(isError403(err)){
        createToast(vue, `${actionDesc} Disallowed By Endpoint Control`, 'Contact your administrator to receive permission to perform this action', 'warning');
    }else{
        console.log(err);
        if(!skipGeneralError){
            createToast(vue, `An Error Occured Durring ${actionDesc}`, 'Try uploading again, if the problem persists, contact support', 'danger');
        }
        if(callOnGeneralError != null){
            callOnGeneralError(err);
        }
    }
}

module.exports = {
    setWindowTitle,
    updateWindowPath,
    updateWindowPathAndSetTitle,
    isError401,
    isError403,
    isError404,
    isError409,
    isError500,
    hasAPIResponseFormat,
    createToast,
    cleaners,
    formatters,
    axios,
    axiosInstance : instance,
    instance: instance,
    customInstance: customInstance,
    uuidv4: uuidv4,
    uuid: uuidv4,
    cloneDeep,
    validate,
    underscore: _,
    axiosErrorIsCancel,
    DateTime,
    luxon: DateTime,
    Luxon: DateTime,
    apiRequestErrorHandling,
    runAPIError: apiRequestErrorHandling,
}