/*
*   Purpose: This is the Vue-Router configuration
*/ 

import Vue from 'vue'
import Router from 'vue-router'
//import Home from './views/Home.vue'
import Login from './public/Login.vue'
import Registration from './public/Registration.vue'
import ForgotPassword from './public/ForgotPassword.vue'
import ChangePassword from './public/ChangePassword.vue'
//import Stage from './views/Stage.vue'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import VueSignaturePad from 'vue-signature-pad';

import {BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Router)
Vue.use(VueApexCharts)
Vue.use(VueSignaturePad)
Vue.component('apexchart', VueApexCharts)

const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const _ = require('underscore');

const router = new Router({
    mode: 'history',
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        //Public Routes
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: {
                title: "Login",
                requiresAuth: false
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Registration,
            meta: {
                title: "Register",
                requiresAuth: false
            }
        },
        {
            path: '/forgot_password',
            name: 'forgotpassword',
            component: ForgotPassword,
            meta: {
                title: "Password Recovery",
                requiresAuth: false
            }
        },
        {
            path: '/change_password/:resetID',
            name: 'changepassword',
            props: true,
            component: ChangePassword,
            meta: {
                title: "Set New Password",
                requiresAuth: false
            }
        },
        // Private Routes
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "Stage" */ './core/Stage.vue'),
            meta: {
                title: "Home",
                requiresAuth: true
            },
            children: [
                {
                    path: '/home',
                    name: 'dashboard',
                    component: () => import(/* webpackChunkName: "Dashboard" */ './views/Dash.vue'),
                    meta: {
                        title: "Home",
                        requiresAuth: true,
                        uipcPath: ['dashboard', 'access']
                    },
                },
                // {
                //     path: '/home/map',
                //     name: 'BaseMap',
                //     component: () => import(/* webpackChunkName: "Base Map" */ './components/BaseMap.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/outstanding',
                //     name: 'OutstandingMapTest',
                //     component: () => import(/* webpackChunkName: "OutstandingWork" */ './components/OutstandingWork.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/choice_lists/:choiceList?',
                    name: 'choice_lists',
                    props: true,
                    component: () => import(/* webpackChunkName: "Choice Lists" */ './views/ValueAliasChoiceSelection.vue'),
                    meta: {
                        titleSetManually: true,
                        requiresAuth: true,
                        uipcPath: ['choiceLists', 'access']
                    },
                },
                // {
                //     path: '/home/customers',
                //     name: 'customers',
                //     component: () => import(/* webpackChunkName: "Customers" */ './views/Customers.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/connections/:pageNum',
                //     name: 'connections',
                //     component: () => import(/* webpackChunkName: "Connections" */ './views/Connections.vue'),
                //     props: true,
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/sites',
                //     name: 'sites',
                //     component: () => import(/* webpackChunkName: "Sites" */ './views/Sites.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/devices',
                //     name: 'devices',
                //     component: () => import(/* webpackChunkName: "Devices" */ './views/Devices.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/devices/:deviceID',
                //     name: 'device_edit',
                //     component: () => import(/* webpackChunkName: "DeviceEdit" */ './views/applets/DeviceEdit.vue'),
                //     props: true,
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/test/:eventID',
                    name: 'test_view',
                    component: () => import(/* webpackChunkName: "TestView" */ './views/applets/BFPATests.vue'),
                    props: true,
                    meta: {
                        title: "BFPA Test",
                        requiresAuth: true,
                        uipcPath: ['viewTest', 'access']
                    },
                },
                {
                    path: '/home/search/:searchTerm',
                    name: 'records_search',
                    component: () => import(/* webpackChunkName: "Search" */ './views/applets/SearchResults.vue'),
                    props: true,
                    meta: {
                        title: "Search",
                        requiresAuth: true,
                        uipcPath: ['search', 'access']
                    },
                },
                // {
                //     path: '/home/testing',
                //     name: 'testbed',
                //     component: () => import(/* webpackChunkName: "TestBed" */ './views/TestBed.vue'),
                //     props: true,
                //     meta: {
                //         title: "Development Testing",
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/leaf',
                //     name: 'leafmap',
                //     component: () => import(/* webpackChunkName: "LeafletMap" */ './components/LeafletMap.vue'),
                //     props: true,
                //     meta: {
                //         title: "Leaflet Development Testing",
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/deviceeventlist/:parentID',
                //     name: 'testbed',
                //     component: () => import(/* webpackChunkName: "eventlisttestbed" */ './views/testing/EventListTest.vue'),
                //     props: true,
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/ptabletesting',
                //     name: 'paginatedtabletesting',
                //     component: () => import(/* webpackChunkName: "PaginatedTableTest" */ './views/PaginatedTableTest.vue'),
                //     props: false,
                //     meta: {
                //         title: "Development Paginated Table Testing",
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/form/:id/:inspect',
                    name: 'dynamicform',
                    component: () => import(/* webpackChunkName: "DynamicForm" */ './components/DynamicForm.vue'),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        uipcPath: ['form', 'access']
                    },
                    beforeEnter: (to, from, next) =>{
                        if(store.getters.hasAccessToFormID({ formID: to.params.id })){
                            next();
                        }else{
                            console.log(`User Does Not Have Access To The Specified Form ID: ${to.params.id}, Attempting To Find Equivalent Form With User Permissions...`);
                            instance.get(process.env.VUE_APP_API_BASE_URL + `/forms/type/${to.params.id}.json`)
                            .then(async (res)=>{
                                var defaultFormForType = store.getters.getAvailableFormsByInspectionType({inspecting: res.data.result.formInspects});
                                if(defaultFormForType.length > 0){
                                    to.params.id = defaultFormForType[0].id;
                                    next(`/home/form/${to.params.id}/${to.params.inspect}`);
                                }else{
                                    console.log(`No Matching Form For Type: ${res.data.result.formInspects}`)
                                    to.params.id = 'no_form_access';
                                    next();
                                }
                            })
                            .catch((err)=>{
                                console.log("An Error Occured While Finding Equivalent Form")
                                console.log(err);
                                to.params.id = 'no_form_access';
                                next();
                            })
                            
                        }
                        
                    }
                },
                {
                    path: '/home/survey/:eventID',
                    name: 'surveyviewer',
                    component: () => import(/* webpackChunkName: "SurveyViewer" */ './views/applets/SurveyApplet.vue'),
                    props: true,
                    meta: {
                        title: "Survey",
                        requiresAuth: true,
                        uipcPath: ['viewSurvey', 'access']
                    },
                },
                {
                    path: '/home/utilities',
                    name: 'utilities',
                    component: () => import(/* webpackChunkName: "Utilities" */ './views/Utilities.vue'),
                    meta: {
                        title: "Utilities",
                        requiresAuth: true,
                        uipcPath: ['utilities', 'access']
                    },
                },
                // {
                //     path: '/home/testFormCert',
                //     name: 'Tech Cert Test',
                //     component: () => import(/* webpackChunkName: "TestTechnicianCert" */ './views/testing/TSTTechnicianCert.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/technicians',
                    name: 'technicians',
                    component: () => import(/* webpackChunkName: "Technicians" */ './views/Technicians.vue'),
                    meta: {
                        title: "Technicians",
                        requiresAuth: true,
                        uipcPath: ['technicians', 'access']
                    }
                },
                {
                    path: '/home/device_models',
                    name: 'device_models',
                    component: () => import(/* webpackChunkName: "Device Models" */ './views/DeviceModels.vue'),
                    meta: {
                        title: "Device Models",
                        requiresAuth: true,
                        uipcPath: ['deviceModels', 'access']
                    },
                },
                // {
                //     path: '/home/event_list',
                //     name: 'event_list',
                //     component: () => import(/* webpackChunkName: "Event List" */ './views/Events.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                // {
                //     path: '/home/bfpa_test_testing',
                //     name: 'testing_event_bfpa_tests',
                //     component: () => import(/* webpackChunkName: "BFPA Test Testing" */ './views/testing/EventBFPATest.vue'),
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/add/test/:deviceID',
                    name: 'testentry',
                    component: () => import(/* webpackChunkName: "BFPATestEntry" */ './views/applets/TestEntry.vue'),
                    props: true,
                    meta: {
                        title: "Add BFPA Test",
                        requiresAuth: true,
                        uipcPath: ['eventList', 'actions', 'addTest']
                    },
                },
                {
                    path: '/home/welcome',
                    name: 'welcome',
                    component: () => import(/* webpackChunkName: "WelcomeFirstTime" */ './views/applets/NewUserWelcome.vue'),
                    props: true,
                    meta: {
                        title: "Hello",
                        requiresAuth: true,
                    },
                },
                {
                    path: '/home/add/repinst/:actionType/:inspectID',
                    name: 'installorreplacement',
                    component: () => import(/* webpackChunkName: "Install or Replacement" */ './views/applets/BFPAReplacement.vue'),
                    props: true,
                    meta: {
                        title: "BFPA Install or Replacement",
                        requiresAuth: true,
                        uipcPath: ['eventList', 'actions', 'replaceInstallDevice']
                    },
                },
                // {
                //     path: '/home/compat',
                //     name: 'testingfilecompatibility',
                //     component: () => import(/* webpackChunkName: "testingfilecompatibility" */ './views/Compat.vue'),
                //     props: true,
                //     meta: {
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/create/customers',
                    name: 'createcustomer',
                    component: () => import(/* webpackChunkName: "Create Customer" */ './views/create/Customers.vue'),
                    props: true,
                    meta: {
                        title: "Create Customer",
                        requiresAuth: true,
                        uipcPath: ['dashboard', 'actions', 'createCustomer']
                    },
                },
                {
                    path: '/home/create/sites/:parent_id?',
                    alias: '/home/create/sites',
                    name: 'createsites',
                    component: () => import(/* webpackChunkName: "Create Sites" */ './views/create/Sites.vue'),
                    props: true,
                    meta: {
                        title: "Create Site",
                        requiresAuth: true,
                        uipcPath: ['dashboard', 'actions', 'createSite']
                    },
                },
                {
                    path: '/home/create/connections/:parent_id?',
                    alias: '/home/create/connections',
                    name: 'createconnections',
                    component: () => import(/* webpackChunkName: "Create Connections" */ './views/create/Connections.vue'),
                    props: true,
                    meta: {
                        title: "Create Connection",
                        requiresAuth: true,
                        uipcPath: ['dashboard', 'actions', 'createConnection']
                    },
                },
                {
                    path: '/home/create/devices/:parent_id?',
                    alias: '/home/create/devices',
                    name: 'createdevices',
                    component: () => import(/* webpackChunkName: "Create Devices" */ './views/create/Devices.vue'),
                    props: true,
                    meta: {
                        title: "Create Device",
                        requiresAuth: true,
                        uipcPath: ['dashboard', 'actions', 'createDevice']
                    },
                },
                {
                    path: '/home/fulcrum/bulkissue',
                    alias: '/home/fulcrum/issue/bulk',
                    name: 'fulcrumbulkissue',
                    component: () => import(/* webpackChunkName: "Bulk Issue To Fulcrum" */ './views/applets/FulcrumBulkIssue.vue'),
                    props: false,
                    meta: {
                        title: "Fulcrum Bulk Issue",
                        requiresAuth: true,
                        uipcPath: ['fulcrumBulkIssue', 'access']
                    },
                },
                {
                    path: '/home/closeout',
                    alias: '/home/closeout/list',
                    name: 'closeout',
                    component: () => import(/* webpackChunkName: "Work Order Close Out" */ './views/applets/Closeout.vue'),
                    props: false,
                    meta: {
                        title: "Closeouts",
                        requiresAuth: true,
                        uipcPath: ['closeouts', 'access']
                    },
                },
                {
                    path: '/home/devicestogeocode',
                    alias: '/home/devicestogeocode/list',
                    name: 'bulkgeocodemissing',
                    component: () => import(/* webpackChunkName: "Devices To Geocode" */ './views/applets/DevicesToGeocode.vue'),
                    props: false,
                    meta: {
                        title: "Geocode Devices",
                        requiresAuth: true,
                        uipcPath: ['bulkGeocode', 'access']
                    },
                },
                {
                    path: '/home/comment/:eventID',
                    name: 'viewtaskcomment',
                    component: () => import(/* webpackChunkName: "TaskCommentViewer" */ './views/applets/TaskComment.vue'),
                    props: true,
                    meta: {
                        title: "Task Comment and Flags",
                        requiresAuth: true,
                        uipcPath: ['viewTaskComment', 'access']
                    },
                },
                {
                    path: '/home/replacement/:eventID',
                    name: 'viewbfpareplacement',
                    component: () => import(/* webpackChunkName: "BFPAReplacementViewer" */ './views/applets/BFPAReplacementView.vue'),
                    props: true,
                    meta: {
                        title: "BFPA Replacement",
                        requiresAuth: true,
                        uipcPath: ['viewReplacementInstall', 'access']
                    },
                },
                {
                    path: '/home/listbuilder/:listID?',
                    alias: '/home/listbuilder',
                    name: 'listbuilder',
                    component: () => import(/* webpackChunkName: "ListBuilder" */ './views/ListBuilder.vue'),
                    props: true,
                    meta: {
                        title: "List Builder",
                        requiresAuth: true,
                        uipcPath: ['listBuilder', 'access']
                    },
                },
                // {
                //     path: '/home/tinymce',
                //     name: 'tinymceeditor',
                //     component: () => import(/* webpackChunkName: "TinyMCEEditor" */ './components/tinyMCEeditor.vue'),
                //     meta: {
                //         title: "Rich Text Editor",
                //         requiresAuth: true
                //     },
                // },
                {
                    path: '/home/listbuilder/actions/issuetofulcrum/:listID',
                    name: 'issuetofulcrum',
                    component: () => import(/* webpackChunkName: "ListBuilderActionsIssueToFulcrum" */ './views/applets/listbuilderactions/IssueToFulcrum.vue'),
                    props: true,
                    meta: {
                        title: "Issue List To Fulcrum",
                        requiresAuth: true,
                        uipcPath: ['fulcrumBulkIssue', 'access']
                    },
                },
                {
                    path: '/home/emailbuilder/:emailTemplateID?/:listID?',
                    alias: ['/home/emailbuilder/:emailTemplateID', '/home/emailbuilder'],
                    name: 'emailbuilder',
                    component: () => import(/* webpackChunkName: "EmailTemplateEditor" */ './views/applets/EmailBuilder.vue'),
                    props: true,
                    meta: {
                        title: "Email Template Editor",
                        requiresAuth: true,
                        uipcPath: ['emailBuilder', 'access']
                    },
                },
                {
                    path: '/home/letterbuilder/:letterTemplateID?/:listID?',
                    alias: ['/home/letterbuilder/:letterTemplateID', '/home/letterbuilder'],
                    name: 'letterbuilder',
                    component: () => import(/* webpackChunkName: "LetterTemplateEditor" */ './views/applets/LetterBuilder.vue'),
                    props: true,
                    meta: {
                        title: "Letter Template Editor",
                        requiresAuth: true,
                        uipcPath: ['letterBuilder', 'access']
                    },
                },
                {
                    path: '/home/batchmanager',
                    name: 'batchmanager',
                    component: () => import(/* webpackChunkName: "LetterBatchManager" */ './views/applets/BatchManager.vue'),
                    props: false,
                    meta: {
                        title: "Letter Batch Manager",
                        requiresAuth: true,
                        uipcPath: ['letterBatchManager', 'access']
                    },
                },
                {
                    path: '/home/endpointpermissionbuilder',
                    name: 'endpointpermissionbuilder',
                    component: () => import(/* webpackChunkName: "EndpointPermissionsBuilder" */ './views/applets/EndpointPermissionsBuilder.vue'),
                    props: false,
                    meta: {
                        title: "Endpoint Permissions Builder",
                        requiresAuth: true
                    },
                },
                {
                    path: '/home/uipermissionbuilder',
                    name: 'uipermissionbuilder',
                    component: () => import(/* webpackChunkName: "UIPermissionsBuilder" */ './views/applets/UIPermissionsBuilder.vue'),
                    props: false,
                    meta: {
                        title: "UI Permissions Builder",
                        requiresAuth: true
                    },
                },
                {
                    path: '/home/formbuilder/:activeFormID?',
                    name: 'formbuilder',
                    component: () => import(/* webpackChunkName: "Form Builder" */ './views/applets/FormBuilder.vue'),
                    props: true,
                    meta: {
                        titleSetManually: true,
                        requiresAuth: true,
                        uipcPath: ['formBuilder', 'access']
                    },
                },
                {
                    path: '/home/roz/formassignment',
                    name: 'formassignment',
                    component: () => import(/* webpackChunkName: "FormAssignment" */ './views/applets/FormAssignment.vue'),
                    props: false,
                    meta: {
                        title: "Form Assignment",
                        requiresAuth: true,
                        uipcPath: ['formAssignment', 'access']
                    },
                },
                {
                    path: '/home/404',
                    name: 'fourohfour',
                    component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
                    props: false,
                    meta: {
                        title: "404 - Page Not Found",
                        requiresAuth: false,
                    },
                },
                {
                    path: '/home/upload/start',
                    name: 'bulkinsertupdatestart',
                    component: () => import(/* webpackChunkName: "BulkInsertUpdateStart" */ './views/applets/BulkInsertUpdateStart.vue'),
                    props: false,
                    meta: {
                        title: "Create or Update Records (CSV) - Phase 1 - Start",
                        requiresAuth: true,
                        uipcPath: ['bulk', 'actions','perform']
                    },
                },
                {
                    path: '/home/upload/assign/:sessionID',
                    name: 'bulkinsertupdateassignment',
                    component: () => import(/* webpackChunkName: "BulkInsertUpdateAssign" */ './views/applets/BulkInsertUpdateAssign.vue'),
                    props: true,
                    meta: {
                        title: "Create or Update Records (CSV) - Phase 2 - Field Assignment",
                        requiresAuth: true,
                        uipcPath: ['bulk', 'actions','perform']
                    },
                },
                {
                    path: '/home/upload/issues/:sessionID',
                    name: 'bulkinsertupdateissues',
                    component: () => import(/* webpackChunkName: "BulkInsertUpdateIssues" */ './views/applets/BulkInsertUpdateIssues.vue'),
                    props: true,
                    meta: {
                        title: "Create or Update Records (CSV) - Phase 3 - Issues and Confirmation",
                        requiresAuth: true,
                        uipcPath: ['bulk', 'actions','perform']
                    },
                },
                {
                    path: '/home/fulcrum/issues/fix',
                    name: 'bulkfixfulcrumissuefailures',
                    component: () => import(/* webpackChunkName: "BulkFixFulcrumIssuesFailures" */ './views/applets/BulkFixFulcrumIssuesFailures.vue'),
                    props: false,
                    meta: {
                        title: "Fix Fulcrum Bulk Issues",
                        requiresAuth: true,
                    },
                },
                {
                    path: '/home/upload/session/list',
                    name: 'BulkInsertUpdateSessionList',
                    component: () => import(/* webpackChunkName: "BulkInsertUpdateSessionList" */ './views/applets/BulkInsertUpdateSessionList.vue'),
                    props: false,
                    meta: {
                        title: "Create or Update Records (CSV) - Session List",
                        requiresAuth: true,
                        uipcPath: ['bulk', 'actions','list']
                    },
                },
                {
                    path: '/home/workorder2/:deviceID?',
                    name: 'EnterWorkOrder 2',
                    component: () => import(/* webpackChunkName: "EnterWorkOrder" */ './views/applets/WOEntry.vue'),
                    props: true,
                    meta: {
                        title: "Enter Work Order",
                        requiresAuth: true,
                    },
                },
                {
                    path: '/home/workorder/:deviceID?',
                    name: 'EnterWorkOrder',
                    component: () => import(/* webpackChunkName: "EnterWorkOrder" */ './views/applets/WOEntry2.vue'),
                    props: true,
                    meta: {
                        title: "Enter Work Order",
                        requiresAuth: true,
                    },
                },
                {
                    path: '/home/maplist',
                    name: 'MapList',
                    component: () => import(/* webpackChunkName: "MapList" */ './components/MapList.vue'),
                    props: true,
                    meta: {
                        title: "Map List",
                        requiresAuth: true,
                    },
                },
                {
                    path: '/home/account/manage',
                    name: 'AccountManagement',
                    component: () => import(/* webpackChunkName: "AccountManagement" */ './views/applets/AccountManagement.vue'),
                    props: false,
                    meta: {
                        title: "Account Management",
                        requiresAuth: true,
                        uipcPath: ['accountManagement', 'access']
                    },
                },
                {
                    path: '/home/hazardvstype/manage',
                    name: 'HazardVsType',
                    component: () => import(/* webpackChunkName: "HazardVsType" */ './views/applets/HazardVsTypeSetup.vue'),
                    props: false,
                    meta: {
                        title: "Protection Sufficiency Management",
                        requiresAuth: true,
                        uipcPath: ['accountManagement', 'access']
                    },
                },
                {
                    path: '/home/sitetypeinterval/manage',
                    name: 'SiteTypeInterval',
                    component: () => import(/* webpackChunkName: "SiteTypeInterval" */ './views/applets/SiteTypeIntervalSetup.vue'),
                    props: false,
                    meta: {
                        title: "Site Type Inveral Management",
                        requiresAuth: true,
                        uipcPath: ['accountManagement', 'access']
                    },
                },
                {
                    path: '/home/customtestreport/:templateID?',
                    name: 'CustomTestReport',
                    props: true,
                    component: () => import(/* webpackChunkName: "CustomTestRepBuilder" */ './views/applets/CustomTestReportBuilder.vue'),
                    meta: {
                        title: "Custom Test Report Builder",
                        requiresAuth: true
                    },
                },
                {
                    path: '/home/generictaskentry/:parentID',
                    name: 'GenericTaskEntry',
                    props: true,
                    component: () => import(/* webpackChunkName: "GenericTaskEntryForm" */ './views/applets/GenericTaskEntry.vue'),
                    meta: {
                        title: "Enter Generic Task",
                        requiresAuth: true
                    },
                },
                {
                    path: '/home/generictask/:eventID',
                    name: 'GenericTaskView',
                    props: true,
                    component: () => import(/* webpackChunkName: "GenericTaskEntryViewer" */ './views/applets/GenericTaskView.vue'),
                    meta: {
                        title: "Generic Task",
                        requiresAuth: true
                    },
                },
            ]
        }
    ]
});

router.beforeEach( async (to, from, next) => {
    if(to == from){
        console.log(`Naviation Attempted Duplicate`)
    }
    if(to.meta.requiresAuth){
        // Check if window denotes a logout occured
        if(window.localStorage.getItem('aswLoggedOut') == 'true'){
            console.log("Router Found Uncleared Logout");
            var redirect = encodeURIComponent(to.fullPath)
            next('/?redir=' + redirect);
        }else if(store.getters.isAuthenticated){ // check if the user is authenticated
            // the next method allow the user to continue to the router 
            next(); 
        }else{
            // Ask API is User Is Authenticated
            instance.get(process.env.VUE_APP_API_BASE_URL + '/auth/check')
            .then(async (res)=>{
                // If the user is authenticated, we dont know the state of all the user configs, so we need to fetch them
                store.dispatch('initLoggedInUser', true)
                .then((result)=>{
                    if(result && store.getters.isAuthenticated){
                        //console.log("User is Authenticated Via JWT");
                        next();
                    }else{
                        //console.log("User is NOT Authenticated Via JWT");
                        var redirect = encodeURIComponent(to.fullPath)
                        next('/?redir=' + redirect);
                    }
                })
            })
            .catch((err)=>{
                // Send the user to the login page with a redirect to their requested location attached
                var redirect = encodeURIComponent(to.fullPath)
                next('/?redir=' + redirect);
            })
        }
    }else {
        // Go Wherever They Asked, No Auth Required For The Requested Route
        next()
    }
});

router.beforeEach((to, from, next) => {
    if(_.has(to.meta, 'uipcPath')){
        if( store.getters.checkUIPC({path: to.meta.uipcPath }) ){
            next();
        }else{
            next('/home/404');
        }
    }else{
        next();
    }
});

const DEFAULT_TITLE = 'ValveAI';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        if(to.meta.titleSetManually == true){
            // Dont Set Title
        }else{
            document.title = to.meta.title || DEFAULT_TITLE;
        }
    });
});
export default router;
