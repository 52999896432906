/*
*   Purpose: This is the main mount point for the SPA Vue instance.
*/ 

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';

//import Vuelidate from 'vuelidate'
//Vue.use(Vuelidate)
//import pubkey from './pubkey'
//sync(store, router);
//import DynamicForm from "@/components/DynamicForm.vue";

Vue.config.productionTip = false

//Vue.component('dyn-form', DynamicForm);

Vue.use(PrimeVue);


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')