<template>
    <b-container fluid>
        <div class="login">
            <div class="login-gauss"></div>
        </div>
        <b-row align-h="center" class="login-tall-row">
            <b-col cols="12" md="8" lg="6" xl="4" align-self="center" class="login-box">
                <b-row align-v="center">
                    <b-col></b-col>
                    <b-col>
                        <b-img src="img/vai_full_stacked.png" fluid alt="ValveAI Logo" class="login-logo"></b-img>
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <hr>
                        <transition name="issueprompt">
                            <div v-show = "showIssueText" class="slim">
                                {{issueText}}
                            </div>
                        </transition>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form @submit="onSubmit">
                            <b-form-group
                                id="username-group"
                                label="Email"
                                label-for="username"
                            >
                                <b-form-input
                                    id="username"
                                    v-model="form.username"
                                    type="email"
                                    autocomplete="on"
                                    required
                                    :state="invalidLogin"
                                    @input="onChange"
                                    
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="password-group"
                                label="Password"
                                label-for="password"
                            >
                                <b-form-input
                                    id="password"
                                    v-model="form.password"
                                    required
                                    type="password"
                                    :state="invalidLogin"
                                    @input="onChange"
                                ></b-form-input>
                            </b-form-group>
                            <b-button block type="submit" variant="success">
                                <b-spinner v-if="formWaiting" label="Spinning"></b-spinner>
                                <span v-if="!formWaiting">Sign In</span>
                                </b-button>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <hr>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- <b-col class="text-center">
                        <b-button disabled variant="light" size="sm" :to="{ path: 'register'}" append>Create An Account</b-button>
                    </b-col> -->
                    <b-col class="text-center">
                        <b-button variant="light" size="sm" href="/forgot_password">Forgot Your Password</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
const axios = require('axios');
const uuidv4 = require('uuid/v4');
const butils = require('../libs/basicUtils.js');

export default {
    name: 'login',
    components: {
    },
    data(){
        
        var formbase = {
            username:"",
            password:""
        };
        return{
            form: formbase,
            invalidLogin: null,
            showIssueText: false,
            issueText: '',
            formWaiting: false,
            redirect: false,
            redirectTo: "/home"
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.formWaiting = true;
            var values = this.form;
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + '/auth/login', values)
            .then(async (response) => {
                this.$store.dispatch('initLoggedInUser', true)
                .then((result)=>{
                    console.log(result)
                    if(result && this.$store.getters.isAuthenticated){
                        if(this.redirect){
                            this.$router.push({path: `${this.redirectTo}`});
                        }else{
                            this.$router.push({path:"/home"});
                        }
                    }else{
                        console.log("Failed To Initialize User Post Login")
                        butils.createToast(this, 
                        "Something Went Wrong While Preparing The User Environment", 
                        `The login attempt was succesful. But something went wrong while initializing your session.
                        Try logging in again, if the problem persists, contact support.`, 
                        "warning", 90);
                    }
                })
            })
            .catch((error) => {
                console.log("called error")
                console.log(error)
                this.formFailed = true;
                this.formWaiting = false;
                console.log(error.response);
                //Actions On 403
                if(error.response.data.status == 403){
                    this.invalidLogin = false; 
                    this.issueText = "Username or Password Invalid";
                    this.showIssueText = true;
                }else{
                    this.invalidLogin = false;
                    this.issueText = error.response.data.errors[0];
                    this.showIssueText = true;
                }
            });
            //alert(JSON.stringify(this.form));
        },
        onChange(value) {
            if(this.showIssueText){
                this.showIssueText = false;
                this.invalidLogin = null;
            }
        }
    },
    beforeMount(){
        var query = this.$route.query;
        if(query != undefined){
            if(query.redir != undefined){
                this.redirect = true;
                this.redirectTo = query.redir;
                console.log(this.redirectTo);
            }
        }
    },
    mounted(){
        butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/auth/check')
        .then(async (res)=>{
            // If the user is authenticated, we dont know the state of all the user configs, so we need to fetch them
            this.$store.dispatch('initLoggedInUser', true)
            .then((result)=>{
                if(result && this.$store.getters.isAuthenticated){
                    if(this.redirect){
                        // The user has mounted the page with a redirect, but they are already logged in... 
                        // ??? Wat ???
                        // I dont know how the user has done this, but we should support them in their ridiculous endeavor
                        this.$router.push({path: `${this.redirectTo}`});
                    }else{
                        this.$router.push({path:"/home"});
                    }
                }else{
                    // Could Not Login This User
                    console.log("Failed To Initialize User Post Login");
                    butils.createToast(this, 
                    "Something Went Wrong While Redirecting To Dashboard", 
                    `It looks like you're already logged in in another window or tab, 
                    but something went wrong while automatically resuming your session. 
                    Try logging in normally, if that doesn't work, contact support.`, 
                    "warning", 90);
                }
            })
        })
        .catch((err)=>{
            // This error would be expected if the user is on the login page, they shouldn't be authenticated
        })
    }
}
</script>

<style scoped>
.login-logo{
    max-height: 30vh;
}
.login {
    position: absolute;
    height:100vh;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:right top;
    background-image: url('../assets/home-bg-min.jpg');
    background-attachment: fixed;
    margin: 0px;
    left:0px;
}
.login-gauss {
    position: absolute;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0px;
    left:0px;
}
.login-box {
    background-color: rgba(255, 255, 255, 0.40);
    border-radius: 1.5rem;
    padding-top: 1em;
    padding-bottom: 1.5em;
    color:rgba(0, 0, 0, 0.9);
}
.login-tall-row{
    height: 100vh;
}
input{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
input:focus{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
b-form-input:focus{
    background-color: rgba(255, 255, 255, 0.60);
}
.issueprompt-enter-active, .issueprompt-leave-active {
    -webkit-transition-property: height; /* Safari */
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-property: height;
    transition-duration: 0.5s;

}
.issueprompt-enter, .issueprompt-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 50px;
}
.login {
  background-image: url('../assets/home-bg-min.jpg');
}
</style>

