/*
*   Purpose: This is the Vuex Store
*/ 

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

const _ = require('underscore');
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['fulcrumFormStore', 'formConfig', 'uiControl', 'accountConfig']
});

const store = new Vuex.Store({
  state: {
    Authenticated: false,
    jwt: {
      raw: null,
      user: {
        id: null,
        account: null,
        first_name: null,
        last_name: null
      }
    },
    user: {
      id: null,
      account: null,
      first_name: null,
      last_name: null,
      display_name: null
    },
    config: {

    },
    client_claims_auth: null,
    forms:{},
    formValidation:{},
    lastFormActionCall:{},
    fulcrumFormStore:{
      lastFetched: null,
      forms: [],
      lastSelectedForm: null,
      lastSelectedStatus: null
    },
    formConfig: {
      hasFetched: false,
      availableForms: []
    },
    uiControl:{
      uipc: null,
      hasFetched: false
    },
    accountConfig:{
      hasFetched: false,
      name: null,
      portalAvailable: false,
      userTechnicianID: null,
      mapDefaults: null
    },
    sessionConfig:{
      flagDeleteSkipAsk: false
    }
  },
  getters:{
    isAuthenticated: state => {
      return state.Authenticated;
    },
    fullusername: state => {
      return state.user.first_name + " " + state.user.last_name;
    },
    userID: state =>{
      return state.user.id;
    },
    allUserInfo: state=>{
      return state.user;
    },
    accountID: state =>{
      return state.user.account;
    },
    firstName: state => {
      return state.user.first_name;
    },
    displayName: state => {
        return state.user.display_name;
    },
    userTechnicianID: state => {
      return state.accountConfig.userTechnicianID;
    },
    getFormValue: (state) => (payload) =>{ // Updated
      /*
        Expects Payload:
        payload = {
          parentFormID: parentFormID,
          dataName: dataName,
          index: index
        }
      */
      // Check Payload
      var hasIndex = false;
      var isArrayNew = false;
      var dataName = null;
      if(!_.has(payload, 'parentFormID')){
        // console.log(`State - Register Form Value Payload Missing Required Key "parentFormID". dataName: ${payload.dataName}`)
        return;
      }
      if(!_.has(payload, 'dataName')){
        // console.log(`State - Register Form Value Payload Missing Required Key "dataName". type: ${payload.type}`)
        return;
      }else{
        dataName = payload.dataName;
      }

      // if has index
      // -1 is not in an array
      // -2 means new
      // any other number is the index of the item in the array
      if(_.has(payload, 'index')){
        if(payload.index != -1){
          hasIndex = true;
          if(payload.index == -2){
            isArrayNew = true;
          }
        }
      }

      if(hasIndex){
        // is child of array engine
        if(isArrayNew){
          return state.forms[payload.parentFormID][dataName].new.value;
        }else{
          return state.forms[payload.parentFormID][dataName].children[payload.index.toString()].value;
        }
      }else{
        // isnt child of array engine
        // if its the actual array engine return the value as an array
        if(state.forms[payload.parentFormID][dataName].type == 'formarrayengine'){
          // Flatten the object into an array
          var arrayRes = [];
          var childIndexes = _.keys(state.forms[payload.parentFormID][dataName].value);
          childIndexes.forEach((key)=>{
            arrayRes.push(state.forms[payload.parentFormID][dataName].value[key]);
          })
          return arrayRes;
        }else{
          return state.forms[payload.parentFormID][dataName].value
        }
      }
        var indexAlt = -1;
        var dataName = payload.dataName;
        if(dataName == undefined){
            return undefined;
        }else if(dataName.includes("-_-")){
            var split = dataName.split("-_-");
            dataName = split[0];
            if(split[1] == 'new'){
                // -2 is special value meaning that this is a new value for the array, but not in the array
                indexAlt = -2;
            }else{
                indexAlt = split[1];
            }
        }
        var index = payload.index || indexAlt;
        if(index == -2){
            return state.forms[payload.parentFormID][dataName].new.value;
        }if(index == -1){
            return state.forms[payload.parentFormID][dataName].value;
        }else{
            return state.forms[payload.parentFormID][dataName].value[index].value;
        }
    },
    getForm: (state) => (payload) =>{ // Updated
      //console.log("Request For Form ID: " + payload);
      return state.forms[payload];     
    },
    getformTitle: (state) => (payload) =>{ 
      if(_.has(state.forms, payload)){
        return state.forms[payload].meta.title ;
      }else{
        return null;
      }
      //console.log("Request Title For Form ID: " + payload);
    },
    getFormValid: (state) => (payload) =>{
      if(_.has(payload, 'parentFormID')){
        var keys = _.keys(state.forms[payload.parentFormID]);
        // console.log(`Form Keys For FormID: ${payload.parentFormID}`)
        // console.log(keys);
        // If we are filtering for a specific set of keys
        if(_.has(payload, 'checkKeys')){
          var checkOnly = payload.checkKeys;
          var dif = _.difference(checkOnly, keys);
          if(dif.length > 0){
            // console.log("Get Form Valid, CheckKeys includes values not in form key array. Enumerated: ");
            // console.log(dif);
          }
          keys = _.intersection(keys, checkOnly);
          //console.log("Keys After Intersection")
          //console.log(keys)
        }
        // Check each key for validity
        var validity = true;
        if(keys.length == 0){
          return validity;
        }
        keys.forEach((k)=>{
          //console.log(`Checking: ${k}`);
          //console.log("Form Has:")
          //console.log(state.forms[payload.parentFormID][k]);
          if(state.forms[payload.parentFormID][k].valid == false){
            validity = false;
          }
        });
        return validity;
      }else{
        return false;
      }
      
    },
    getFulcrumFormStore: (state) => {
      return state.fulcrumFormStore;
    },
    getHasFetchedFormConfig: (state) => {
      return state.formConfig.hasFetched;
    },
    getHasFetchedUIControl: (state) => {
      return state.uiControl.hasFetched;
    },
    getHasFetchedAccountConfig: (state) => {
      return state.uiControl.hasFetched;
    },
    getMapDefaults: (state) => {
      return state.accountConfig.mapDefaults;
    },
    getListBuilderSettings: (state)=>{
      return state.accountConfig.listBuilderSettings;
    },
    getEveryAvailableFormForGivenInspectionType: (state) => (payload) =>{
      if(_.has(payload, 'inspecting')){
        var filteredForms = state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting});
        if(filteredForms.length > 0){
          return filteredForms;
        }else{
          // Try Using ANYTHING we can get, if no default exists
          return state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting});
        }
        
      }else{
        console.log("Getter For getAvailableForms expects payload to contain key 'inspecting'")
        return null;
      }
    },
    getAvailableFormsByInspectionType: (state) => (payload) =>{
      if(_.has(payload, 'inspecting')){
        var filteredForms = state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting && form.is_default});
        if(filteredForms.length > 0){
          return filteredForms;
        }else{
          // Try Using ANYTHING we can get, if no default exists
          return state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting});
        }
        
      }else{
        console.log("Getter For getAvailableForms expects payload to contain key 'inspecting'")
        return null;
      }
    },
    getParentTypeFromFormID: (state) => (payload)=>{
      if(_.has(payload, 'formID')){
        var match = _.find(state.formConfig.availableForms, (form)=>{ return form.id == payload.formID; })
        if(match != undefined){
          return match.form_inspects;
        }else{
          return null;
        }
      }else{
        console.log("Getter For getParentTypeFromFormID expects payload to contain key 'formID'")
        return null;
      }
    },
    getFullStackForm: (state) => (payload) => {
      if(_.has(payload, 'inspecting')){
        var filteredForms = state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting && form.stack_height == 4 && form.is_default});
        if(filteredForms.length > 0){
          return filteredForms;
        }else{
          // Try for one that isnt default
          var anything = state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting && form.stack_height == 4});
          if(anything.length > 0){
            return anything
          }else{
            // Try Using ANYTHING we can get, if no correct stack height exists
            return state.formConfig.availableForms.filter((form)=>{ return form.form_inspects == payload.inspecting});
          }
        }
        
      }else{
        console.log("Getter For getFullStackForm expects payload to contain key 'inspecting'")
        return null;
      }
    },
    hasAccessToFormID: (state) => (payload) =>{
      if(_.has(payload, 'formID')){
        var filteredForms = state.formConfig.availableForms.filter((form)=>{ return form.id == payload.formID});
        if(filteredForms.length > 0){
          return true;
        }else{
          return false;
        }
      }else{
        console.log("Getter For hasAccessToFormID expects payload to contain key 'formID'")
        return false;
      }
    },
    flagDeleteWithoutConfirm: state => {
      return state.sessionConfig.flagDeleteSkipAsk;
    },
    checkUIPC: (state) => (payload) =>{
      if(state.uiControl.uipc == null){
        console.log("UI Control UIPC Is NULL")
      }
      return _.get(state.uiControl.uipc, payload.path, false);
    },
    getAccountConfigPortalAvailable: (state) => {
      return state.accountConfig.portalAvailable;
    }
  },
  mutations: {
    logout(state){
      // TODO: request dump cookies, in case the API cannot be reached to flush them via server side cookie clearing
      state.Authenticated = false;
      state.user = {
        id: null,
        account: null,
        first_name: null,
        last_name: null
      };
      state.config = {}
      state.formConfig = {
        hasFetched: false,
        availableForms: []
      };
      state.uiControl ={
        uipc: null,
        hasFetched: false
      };
      state.fulcrumFormStore = {
        lastFetched: null,
        forms: [],
        lastSelectedForm: null,
        lastSelectedStatus: null
      };
      window.localStorage.setItem('aswLoggedOut', 'true');
    },
    invalidateUserAuth (state){
      state.Authenticated = false;
    },
    flagDeleteDontAsk(state){
      state.sessionConfig.flagDeleteSkipAsk = true;
    },
    setUserInfoFromCookie(state){
      var allRawCookies = document.cookie;
      var decodedCookies = decodeURIComponent(allRawCookies);
      // Split Cookies
      var cookiesAry = decodedCookies.split("; ");
      
      // Get the aswtokenid cookie
      // var tokenIDCookieName = "aswtokenid";
      // Fetch User Info From aswuserinfo Cookie
      var userInfo = null;
      var seekCookie = "aswuserinfo=";
      // var userInfoCookieRegex = /aswuserinfo_[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}=/;

      // Find The Correct Cookie
      var match = null;
      cookiesAry.forEach(raw => {
        var loc = raw.indexOf(seekCookie);
        if (loc != -1) {
          // Found The Cookie With Our Name
          match = raw;
        }
      });

      if (match != null) {
        console.log("Clearing Stored Forms")
        var tmpVal = match.substring(seekCookie.length, match.length);
        userInfo = JSON.parse(tmpVal);
        state.Authenticated = true;
        state.user = userInfo;
        console.log("Init User Completed")
        return true;
      } else {
        console.log("Init User Completed")
        return false;
      }
    },
    setUserNameInfo(state, payload){
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
      state.user.display_name = payload.display_name;
    },
    setDirty(state, payload){
      var dataName = payload.dataName;
      state.forms[payload.parentFormID][dataName].isDirty = true;
      return;
    },
    registerFormValue(state, payload){
      // Payload Expected To Contain
      /*
        payload = {
          parentformID: parentFormID,
          type: string name of form input type
          dataName: dataName,
          index: index in array of values,
          value: value (any)
          valid: boolean
          isDirty: boolean
        }
      */
      // Check Payload
      var hasIndex = false;
      var isArrayNew = false;
      var dirty = null;
      var valid = null;
      var dataName = null;
      if(!_.has(payload, 'parentFormID')){
        // console.log(`State - Register Form Value Payload Missing Required Key "parentFormID". dataName: ${payload.dataName}`)
        return;
      }
      if(!_.has(payload, 'type')){
        // console.log(`State - Register Form Value Payload Missing Required Key "type". dataName: ${payload.dataName}`)
        return;
      }
      if(!_.has(payload, 'dataName')){
        // console.log(`State - Register Form Value Payload Missing Required Key "dataName". type: ${payload.type}`)
        return;
      }else{
        dataName = payload.dataName;
      }
      if(_.has(payload, "valid")){
        valid = (typeof payload.valid === "boolean") ? payload.valid : null;
      }
      if(_.has(payload, "isDirty")){
        dirty = (typeof payload.isDirty === "boolean") ? payload.isDirty : null;
      }

      // if has index
      // -1 is not in an array
      // -2 means new
      // any other number is the index of the item in the array
      if(_.has(payload, 'index')){
        if(payload.index != -1){
          hasIndex = true;
          if(payload.index == -2){
            isArrayNew = true;
          }
        }
      }
      // console.log(`State - Register Form Value - ${payload.type} - ${payload.dataName} ${(hasIndex) ? (isArrayNew) ? "the 'new item' for the array engine": "item " + payload.index + "in array" : ""}`);

      // Check if this dataName needs to be created
      if(!_.has(state.forms[payload.parentFormID], payload.dataName)){
        // this form does not contain a key for the given dataName yet, so we will create one
        if(hasIndex){
          // This is a child of an arrayenginearray, but the form array engine hasnt registered yet.
          state.forms[payload.parentFormID][dataName] = {
            type: 'formarrayengine',
            isDirty: null, 
            valid: null,
          };
          if(isArrayNew){
            // if this is the new item for the array, add it to the array engine under new
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].children = {};
            state.forms[payload.parentFormID][dataName].new = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }else{
            // create the array value and children, then fill
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].value[payload.index.toString()] = payload.value;
            state.forms[payload.parentFormID][dataName].children = {};
            state.forms[payload.parentFormID][dataName].children[payload.index.toString()] = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }
        }else{
          // Not a child of an array engine
          state.forms[payload.parentFormID][dataName] = {
            type: payload.type,
            isDirty: null, 
            valid: valid,
          };
          if(_.has(payload, "isDirty")){
            state.forms[payload.parentFormID][dataName].isDirty = payload.isDirty;
          }
          // Check if this is a formArrayEngine
          if(payload.type == 'formarrayengine'){
            // if it is the value is an object
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].children = {};
          }else{
            state.forms[payload.parentFormID][dataName].value = payload.value;
          }
        }
      }else{ // It already exists
        // Check if this is an array child
        if(hasIndex){
          if(isArrayNew){
            state.forms[payload.parentFormID][dataName].new = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }else{
            // this item belongs to an arrayEngine
            state.forms[payload.parentFormID][dataName].children[payload.index.toString()] = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
            state.forms[payload.parentFormID][dataName].value[payload.index.toString()] = payload.value;
          }
        }else{ // Not an array child
          // Replace all the values with the registration values
          state.forms[payload.parentFormID][dataName].type = payload.type,
          state.forms[payload.parentFormID][dataName].isDirty = dirty;
          state.forms[payload.parentFormID][dataName].valid = valid;
          state.forms[payload.parentFormID][dataName].value = payload.value;
        }
      }

      
       
      
        // var indexAlt = -1;
        // var dataName = payload.dataName;
        // if(dataName == undefined){
        //     return;
        // }else if(dataName.includes("-_-")){
        //     var split = dataName.split("-_-");
        //     dataName = split[0];
        //     if(split[1] == 'new'){
        //         // -2 is special value meaning that this is a new value for the array, but not in the array
        //         indexAlt = 'new';
        //     }else{
        //         indexAlt = split[1];
        //     }
        // }
        // console.log(`${dataName}: Reporting Durring Registration. indexAlt: ${indexAlt} | payloadIndex: ${payload.index} | payloadValue: ${payload.value} | formID: ${payload.parentFormID}`)
        // var value = payload.value;
        // var index = payload.index || indexAlt;
        // var dirty = payload.isDirty || false;
        // var type = payload.type || 'unknown';
        
        // //console.log("Performing Registration With Payload:")
        // //console.log(payload);
        // // console.log("Payload Parsed To:")
        // // console.log({
        // //     dataName: dataName,
        // //     index: index,
        // //     indexAlt: indexAlt,
        // //     valid: valid,
        // //     dirty: dirty,
        // //     value: value
        // // });
        // // console.log("State Currently Has:")
        // // console.log(state.form[dataName])

        // if(!state.forms[payload.parentFormID].hasOwnProperty(dataName)){
        //     console.log("Creating Required Parent");
        //     // If we get here early, just create the parent
        //     state.forms[payload.parentFormID][dataName] = {
        //         isDirty: null, 
        //         valid: null,
        //     };
        //     if(index != -1){
        //         state.forms[payload.parentFormID][dataName].value = []; 
        //     }else{
        //         state.forms[payload.parentFormID][dataName].value = null;
        //     }
        // }
        // console.log(`${dataName} will process based on index ${index}`)
        // if(index == -1){
        //     // Handle Cases Where Parent Does Not Know Child Values
        //     if(value == undefined){
        //         // Dont Set The Value Here
        //         state.forms[payload.parentFormID][dataName].isDirty = dirty;
        //         state.forms[payload.parentFormID][dataName].valid = valid;
        //         state.forms[payload.parentFormID][dataName].type = type;
        //         if(value == null){
        //           // Set The value if it is null
        //           state.forms[payload.parentFormID][dataName].value = value;
        //         }
        //     }else{
        //         state.forms[payload.parentFormID][dataName] = {type: type, value: value, isDirty: dirty, valid: valid };
        //     }
            
        // }else{
        //     if(indexAlt == 'new'){
        //         state.forms[payload.parentFormID][dataName].new = {type: type, value: value, isDirty: dirty, valid: valid };
        //     }else{
        //         console.log(`Is an array item`)
        //         console.log(state.forms[payload.parentFormID][dataName])
        //         console.log(index)
        //         if(_.isArray(state.forms[payload.parentFormID][dataName].value)){
        //           console.log("its already an array")
        //           state.forms[payload.parentFormID][dataName].value[index] = {type: type, value: value, isDirty: dirty, valid: valid };
        //         }else{
        //           state.forms[payload.parentFormID][dataName].value = [];
        //           state.forms[payload.parentFormID][dataName].value[index] = {type: type, value: value, isDirty: dirty, valid: valid };
        //         }
                
        //     }
        // }
    },
    updateFormValue(state, payload){ // Updated
      /*
        payload = {
          parentformID: parentFormID,
          type: string name of form input type
          dataName: dataName,
          index: index in array of values,
          value: value (any)
          valid: boolean
          isDirty: boolean
        }
      */
      // Check Payload
      var hasIndex = false;
      var isArrayNew = false;
      var dirty = null;
      var valid = null;
      var dataName = null;
      if(!_.has(payload, 'parentFormID')){
        // console.log(`State - Update Form Value Payload Missing Required Key "parentFormID". dataName: ${payload.dataName}`)
        return;
      }
      if(!_.has(payload, 'type')){
        // console.log(`State - Update Form Value Payload Missing Required Key "type". dataName: ${payload.dataName}`)
        return;
      }
      if(!_.has(payload, 'dataName')){
        // console.log(`State - Update Form Value Payload Missing Required Key "dataName". type: ${payload.type}`)
        return;
      }else{
        dataName = payload.dataName;
      }
      if(_.has(payload, "valid")){
        valid = (typeof payload.valid === "boolean") ? payload.valid : null;
      }
      if(_.has(payload, "isDirty")){
        dirty = (typeof payload.isDirty === "boolean") ? payload.isDirty : null;
      }

      // if has index
      // -1 is not in an array
      // -2 means new
      // any other number is the index of the item in the array
      if(_.has(payload, 'index')){
        if(payload.index != -1){
          hasIndex = true;
          if(payload.index == -2){
            isArrayNew = true;
          }
        }
      }

      // console.log(`State - Update Form Value - ${payload.type} - ${payload.dataName} ${(hasIndex) ? (isArrayNew) ? "the 'new item' for the array engine": "item " + payload.index + "in array" : ""}`);
      
      // Check if this dataName needs to be created
      if(!_.has(state.forms[payload.parentFormID], payload.dataName)){
        // this form does not contain a key for the given dataName yet, so we will create one
        if(hasIndex){
          // This is a child of an arrayenginearray, but the form array engine hasnt registered yet.
          state.forms[payload.parentFormID][dataName] = {
            type: 'formarrayengine',
            isDirty: null, 
            valid: null,
          };
          if(isArrayNew){
            // if this is the new item for the array, add it to the array engine under new
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].children = {};
            state.forms[payload.parentFormID][dataName].new = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }else{
            // create the array value and children, then fill
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].value[payload.index.toString()] = payload.value;
            state.forms[payload.parentFormID][dataName].children = {};
            state.forms[payload.parentFormID][dataName].children[payload.index.toString()] = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }
        }else{
          // Not a child of an array engine
          state.forms[payload.parentFormID][dataName] = {
            type: payload.type,
            isDirty: null, 
            valid: valid,
          };
          if(_.has(payload, "isDirty")){
            state.forms[payload.parentFormID][dataName].isDirty = payload.isDirty;
          }
          // Check if this is a formArrayEngine
          if(payload.type == 'formarrayengine'){
            // if it is the value is an object
            state.forms[payload.parentFormID][dataName].value = {};
            state.forms[payload.parentFormID][dataName].children = {};
          }else{
            state.forms[payload.parentFormID][dataName].value = payload.value;
          }
        }
      }else{ // It already exists
        // Check if this is an array child
        if(hasIndex){
          if(isArrayNew){
            state.forms[payload.parentFormID][dataName].new = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
          }else{
            // this item belongs to an arrayEngine
            state.forms[payload.parentFormID][dataName].children[payload.index.toString()] = {type: payload.type, value: payload.value, isDirty: dirty, valid: valid };
            state.forms[payload.parentFormID][dataName].value[payload.index.toString()] = payload.value;
          }
        }else{ // Not an array child
          // Replace all the values with the registration values
          state.forms[payload.parentFormID][dataName].type = payload.type,
          state.forms[payload.parentFormID][dataName].isDirty = dirty;
          state.forms[payload.parentFormID][dataName].valid = valid;
          state.forms[payload.parentFormID][dataName].value = payload.value;
        }
      }
        // var indexAlt = -1;
        // var dataName = payload.dataName;
        // if(dataName == undefined){
        //     return;
        // }else if(dataName.includes("-_-")){
        //     var split = dataName.split("-_-");
        //     dataName = split[0];
        //     if(split[1] == 'new'){
        //         // -2 is special value meaning that this is a new value for the array, but not in the array
        //         indexAlt = 'new';
        //     }else{
        //         indexAlt = split[1];
        //     }
        // }
        // var value = payload.value;
        // var valid;
        // if(payload.hasOwnProperty('valid')){
        //     valid = (typeof payload.valid === "boolean") ? payload.valid : null;
        // }else{
        //     valid = null;
        // }
        // var index = payload.index || indexAlt;
        // var dirty = payload.isDirty || false;
        // var type = payload.type || 'unknown';
        
        // // console.log("Performing Registration With Payload:")
        // // console.log(payload);
        // // console.log("Payload Parsed To:")
        // // console.log({
        // //     dataName: dataName,
        // //     index: index,
        // //     indexAlt: indexAlt,
        // //     valid: valid,
        // //     dirty: dirty,
        // //     value: value
        // // });
        // // console.log("State Currently Has:")
        // // console.log(state.form[dataName])

        // if(!state.forms[payload.parentFormID].hasOwnProperty(dataName)){
        //     console.log("Creating Required Parent For UpdateFormValue call");
        //     // If we get here early, just create the parent
        //     state.forms[payload.parentFormID][dataName] = {
        //         isDirty: null, 
        //         valid: null,
        //     };
        //     if(index != -1){
        //         state.forms[payload.parentFormID][dataName].value = []; 
        //     }else{
        //         state.forms[payload.parentFormID][dataName].value = null;
        //     }
        // }
        // if(index == -1){
        //     // Handle Cases Where Parent Does Not Know Child Values
        //     if(value == undefined){
        //         // Dont Set The Value Here
        //         state.forms[payload.parentFormID][dataName].isDirty = dirty;
        //         state.forms[payload.parentFormID][dataName].valid = valid;
        //         state.forms[payload.parentFormID][dataName].type = type;
        //         if(value == null){
        //           // Set The value if it is null
        //           state.forms[payload.parentFormID][dataName].value = value;
        //         }
        //     }else{
        //         state.forms[payload.parentFormID][dataName] = {type: type, value: value, isDirty: dirty, valid: valid };
        //     }
            
        // }else{
        //     if(indexAlt == 'new'){
        //         state.forms[payload.parentFormID][dataName].new = {type: type, value: value, isDirty: dirty, valid: valid };
        //     }else{
        //         state.forms[payload.parentFormID][dataName].isDirty = true;
        //         state.forms[payload.parentFormID][dataName].value[index] = {type: type, value: value, isDirty: dirty, valid: valid };
        //     }
        // }
    },
    removeFormValue(state, payload){ // Updated
      /*
        payload = {
          parentformID: parentFormID,
          dataName: dataName,
          index: index in array of values
        }
      */
      // Check Payload
      var hasIndex = false;
      var isArrayNew = false;
      var dataName = null;
      if(!_.has(payload, 'parentFormID')){
        // console.log(`State - Remove Form Value Payload Missing Required Key "parentFormID". dataName: ${payload.dataName}`)
        return;
      }else{
        if(payload.parentFormID == undefined){
          // console.log(`State - Remove Form Value Payload Missing Required Key "parentFormID". dataName: ${payload.dataName}`)
          return;
        }
      }
      if(!_.has(payload, 'dataName')){
        // console.log(`State - Remove Form Value Payload Missing Required Key "dataName". type: ${payload.type}`)
        return;
      }else{
        dataName = payload.dataName;
      }

      // if has index
      // -1 is not in an array
      // -2 means new
      // any other number is the index of the item in the array
      if(_.has(payload, 'index')){
        if(payload.index != -1){
          hasIndex = true;
          if(payload.index == -2){
            isArrayNew = true;
          }
        }
      }

      // console.log(`State - Remove Form Value - ${payload.dataName} ${(hasIndex) ? (isArrayNew) ? "the 'new item' for the array engine": "item " + payload.index + "in array" : ""}`);
      
      // Check if dataName even exists on this form
      if(!_.has(state.forms[payload.parentFormID], dataName)){
        // Nothing to remove, the entire thing is already gone
      }else{ // It already exists
        // Check if this is an array child
        if(hasIndex){
          if(isArrayNew){
            delete state.forms[payload.parentFormID][dataName].new;
          }else{
            // this item belongs to an arrayEngine, delete only the values that belong to it
            var keys = _.keys(state.forms[payload.parentFormID][dataName].children);
            keys.forEach((fKey)=>{
              if(payload.index == parseInt(fKey, 10)){
                // We want to delete this one
                delete state.forms[payload.parentFormID][dataName].children[payload.index.toString()];
                delete state.forms[payload.parentFormID][dataName].value[payload.index.toString()];
              }else if(payload.index < parseInt(fKey, 10)){
                // Now shuffle the keys down an index
                state.forms[payload.parentFormID][dataName].children[(parseInt(fKey, 10) - 1).toString()] = state.forms[payload.parentFormID][dataName].children[fKey];
                state.forms[payload.parentFormID][dataName].value[(parseInt(fKey, 10) - 1).toString()] = state.forms[payload.parentFormID][dataName].value[fKey];
                delete state.forms[payload.parentFormID][dataName].children[fKey];
                delete state.forms[payload.parentFormID][dataName].value[fKey];
              }
            })
          }
        }else{ // Not an array child
          delete state.forms[payload.parentFormID][dataName]
        }
      }
    },
    clearForm(state, payload){ // Updated
      delete state.forms[payload.localFormID];
        // state.forms[payload.localFormID] = {
        //     meta: {}
        // };
        // state.forms[payload.localFormID].meta = payload.meta || {};
        //state.forms[payload.localFormID].meta.title = 'Loading...';
    },
    initForm(state, payload){
      // payload.localFormID is expected
      // payload.meta is expected
      if(_.has(state.forms, payload.localFormID)){
        // We need to clear the form first. then we can load this form
        delete state.forms[payload.localFormID];
      }
      state.forms[payload.localFormID] = {
        meta: payload.meta
      };
    },
    formPrepend(state, payload){ // Updated
      // This doesnt add anything to the form, it just acts as a listenable event for the array engine
        var locFormID = payload.parentFormID;
        var valid = payload.valid || null;
        var dataName = payload.dataName;
        var index = payload.index;
        state.lastFormActionCall = {parentFormID: locFormID, type: "prepend", dataName: dataName, valid: valid, index: index};
    },
    formAppend(state, payload){ // Updated
        // This doesnt add anything to the form, it just acts as a listenable event for the array engine
        var locFormID = payload.parentFormID;
        var valid = payload.valid || null;
        var dataName = payload.dataName;
        var index = payload.index;
        state.lastFormActionCall = {parentFormID: locFormID, type: "append", dataName: dataName, valid: valid, index: index};
    },
    setFormTitle(state, payload){
      state.forms[payload.parentFormID].meta.title = payload.title;
    },
    updateFulcrumForms(state, payload){
      state.fulcrumFormStore.forms = payload.forms;
      state.fulcrumFormStore.lastFetched = new Date();
    },
    setFulcrumFormsStoreAccess(state, payload){
      state.fulcrumFormStore.lastSelectedForm = payload.lastSelectedForm;
      state.fulcrumFormStore.lastSelectedStatus = payload.lastSelectedStatus;
    },
    setAvailableForms(state, payload){
      state.formConfig.availableForms = _.filter(payload.availableForms, (form)=>{ return form !== null; });
      state.formConfig.hasFetched = true;
    },
    setUIControl(state, payload){
      state.uiControl.uipc = payload.uipc;
      state.uiControl.hasFetched = true;
    },
    setAccountConfig(state, payload){
      state.accountConfig.name = payload.account_name;
      state.accountConfig.portalAvailable = payload.portal_available;
      state.accountConfig.hasFetched = true;
      state.accountConfig.userTechnicianID = payload.user_technician_id || null;
      state.accountConfig.mapDefaults = payload.map_defaults || null;
      state.accountConfig.listBuilderSettings = payload.list_builder_settings || { allow_or: false, allow_grouping: false };
    }
  },
  actions: {
    initLoggedInUser({state, getters, commit, dispatch}, requestUpdate = false){
      return new Promise( async (resolve, reject)=>{
        if(requestUpdate){
          console.log("Initializing User Login - Update Requested");
        }else{
          console.log("Initializing User Login - No Update Requested");
        }
        // Remove Logged Out From The Window Storage
        window.localStorage.removeItem('aswLoggedOut');
        // Setup User Info From The Cookie
        await commit('setUserInfoFromCookie');
        // Fetch the stuff
        var fetchUIPermissionsControl = dispatch('fetchUIPermissionsControl', requestUpdate)
        var fetchFormsProm = dispatch('fetchAvailableForms', requestUpdate);
        var fetchAccountConfig = dispatch('fetchAccountAndUserConfig', requestUpdate);
        Promise.all([fetchUIPermissionsControl, fetchFormsProm, fetchAccountConfig])
        .then((result)=>{
          resolve(true)
        })
        .catch((err)=>{
          // This error can occur
          console.log(err);
          resolve(false);
        })
      })
      
    },
    fetchUIPermissionsControl({state, getters, commit, dispatch}, requestUpdate){
      return new Promise((resolve, reject)=>{
        if(getters.getHasFetchedUIControl && !requestUpdate){
          console.log("Store Has Already Fetched UI Permissions Control")
          // console.log("Have Fetched Available Forms");
          // TODO: Check For Update
          // TODO: Create Object Required For Checking Update
          // TODO: Perform Update Check Against API with the object
          // TODO: Update when the API Says So
          // For now, this just assumes we have the most up to date forms
          resolve(true);
        }else{
          console.log("Store Fetches UI Permissions Control")
          instance.get(process.env.VUE_APP_API_BASE_URL + '/atils/roz/uipc')
          .then((uiControlResponse)=>{
            commit('setUIControl', {uipc: uiControlResponse.data.result.ui_control});
            resolve(true);
          })
          .catch((err)=>{
            console.log(err);
            reject(err);
          })
        }
      })
    },
    fetchAccountConfig({state, getters, commit, dispatch}, requestUpdate){
      return new Promise((resolve, reject)=>{
        if(getters.getHasFetchedAccountConfig && !requestUpdate){
          console.log("Store Has Already Fetched Account Config")
          // console.log("Have Fetched Available Forms");
          // TODO: Check For Update
          // TODO: Create Object Required For Checking Update
          // TODO: Perform Update Check Against API with the object
          // TODO: Update when the API Says So
          // For now, this just assumes we have the most up to date forms
          resolve(true);
        }else{
          console.log("Store Fetches Account Config")
          instance.get(process.env.VUE_APP_API_BASE_URL + '/atils/roz/account_config')
          .then((accountConfigResponse)=>{
            commit('setAccountConfig', accountConfigResponse.data.result);
            resolve(true);
          })
          .catch((err)=>{
            console.log(err);
            reject(err);
          })
        }
      })
    },
    fetchAccountAndUserConfig({state, getters, commit, dispatch}, requestUpdate){
      return new Promise((resolve, reject)=>{
        if(getters.getHasFetchedAccountConfig && !requestUpdate){
          console.log("Store Has Already Fetched Account & User Config")
          // TODO: Check For Update
          // TODO: Create Object Required For Checking Update
          // TODO: Perform Update Check Against API with the object
          // TODO: Update when the API Says So
          // For now, this just assumes we have the most up to date forms
          resolve(true);
        }else{
          console.log("Store Fetches Account Config")
          instance.get(process.env.VUE_APP_API_BASE_URL + '/atils/roz/user_and_account_config')
          .then((accountConfigResponse)=>{
            commit('setAccountConfig', accountConfigResponse.data.result);
            resolve(true);
          })
          .catch((err)=>{
            console.log(err);
            reject(err);
          })
        }
      })
    },
    fetchAvailableForms({state, getters, commit, dispatch}, requestUpdate){
      return new Promise((resolve, reject)=>{
        if(getters.getHasFetchedFormConfig && !requestUpdate){
          // console.log("Have Fetched Available Forms");
          // TODO: Check For Update
          // TODO: Create Object Required For Checking Update
          // TODO: Perform Update Check Against API with the object
          // TODO: Update when the API Says So
          // For now, this just assumes we have the most up to date forms
          resolve(true);
        }else{
          instance.get(process.env.VUE_APP_API_BASE_URL + '/forms/list/user.json')
          .then((listFormsResponse)=>{
            commit('setAvailableForms', {availableForms: listFormsResponse.data.result});
            resolve(true);
          })
          .catch((err)=>{
            console.log(err);
            reject(err);
          })
        }
      })
    },
  },
  plugins: [dataState]
});

export default store;