<template>
    <b-container fluid>
        <div class="login">
            <div class="login-gauss"></div>
        </div>
        <b-row align-h="center" class="login-tall-row">
            <b-col cols="12" md="8" lg="6" xl="4" align-self="center" class="login-box">
                <b-row align-v="center">
                    <b-col></b-col>
                    <b-col>
                        <b-img src="/img/vai_full_stacked.png" fluid alt="ValveAI Logo" class="login-logo"></b-img>
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <hr>
                <b-row align-v="center" class="mt-3">
                    <b-col cols="12" class="text-center">
                        <span class="h3">Change Password</span>
                    </b-col>
                </b-row>
                <hr>
                <b-row v-show="issueText != null" class="mb-3">
                    <b-col class="text-center">
                        <transition name="issueprompt">
                            <div class="slim">
                                {{issueText}}
                            </div>
                        </transition>
                    </b-col>
                </b-row>
                <b-row v-if="showInput" class="mb-3">
                    <b-col cols="12">
                        <b-form @submit="onSubmit">
                            <b-form-group
                                id="password-label"
                                label="Enter A New Password"
                                label-for="password"
                            >
                                <b-form-input
                                    id="password"
                                    v-model="password"
                                    type="password"
                                    required
                                    trim
                                    aria-describedby="password-help-block"
                                ></b-form-input>
                                <b-form-text id="password-help-block">
                                    At least 5 characters, no length limit.<br>
                                    Accepts any valid unicode characters, including emoji, feel free to get as crazy as you want.<br>
                                    Cannot begin or end with spaces. Spaces at the begining or end will be removed.<br>
                                    <br>
                                    Valve Ai Recommends, But Does Not Require The Following Password Characteristics:<br>
                                    <ul>
                                        <li>Length of at least 8 characters.</li>
                                        <li>Upper and lowercase characters</li>
                                        <li>Special characters</li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>
                            <b-form-group
                                id="confirm-password-label"
                                label="Confirm New Password"
                                label-for="confirm-password"
                                :state="invalidLogin"
                            >
                                <b-form-input
                                    id="confirm-password"
                                    v-model="confirmPassword"
                                    type="password"
                                    required
                                    :state="invalidLogin"
                                    trim
                                ></b-form-input>
                            </b-form-group>
                            <b-button block type="submit" variant="success" :disabled="issueText != null || invalidLogin == false || invalidLogin == null">
                                <b-spinner v-if="formWaiting" label="Spinning"></b-spinner>
                                <span v-if="!formWaiting">Change Password</span>
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
const butils = require('../libs/basicUtils.js');

export default {
    name: 'changepassword',
    components:{

    },
    props:{
        resetID:{
            type: String,
            default: () => { return null; }
        }
    },
    data(){
        return{
            showInput: false,
            issueText: null,
            username: null,
            formWaiting: false,
            password: null,
            confirmPassword: null
        }
    },
    methods:{
        checkResetID(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/auth/check_reset_id/${this.resetID}`)
            .then((response)=>{
                this.showInput = true;
            })
            .catch((err)=>{
                console.log(err);
                this.issueText = "Unable To Change Password. This Reset Link May Have Expired Or May Have Already Been Used.";
            })
        },
        onSubmit(event){
            event.preventDefault();
            this.formWaiting = true;
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/auth/reset_password/${this.resetID}`, { password: this.confirmPassword} )
            .then((response)=>{
               this.formWaiting = false; 
               butils.createToast(this, "Success", "Try Logging In With Your New Password", "success", 5);
               this.$router.push({path:"/"});
            })
            .catch((err)=>{
                this.formWaiting = false; 
                console.log(err);
                butils.createToast(this, "Request Failed");
            })
        }
    },
    watch:{

    },
    computed:{
        invalidLogin(){
            if(this.password != null && this.confirmPassword != null){
                if(this.confirmPassword === this.password){
                    return true;
                }else{
                    return false;
                }
            }else{
                return null;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.checkResetID();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
.login-logo{
    max-height: 30vh;
}
.login {
    position: absolute;
    height:100vh;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:right top;
    background-image: url('../assets/home-bg-min.jpg');
    background-attachment: fixed;
    margin: 0px;
    left:0px;
}
.login-gauss {
    position: absolute;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0px;
    left:0px;
}
.login-box {
    background-color: rgba(255, 255, 255, 0.40);
    border-radius: 1.5rem;
    padding-top: 1em;
    padding-bottom: 1.5em;
    color:rgba(0, 0, 0, 0.9);
}
.login-tall-row{
    height: 100vh;
}
input{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
input:focus{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
b-form-input:focus{
    background-color: rgba(255, 255, 255, 0.60);
}
.issueprompt-enter-active, .issueprompt-leave-active {
    -webkit-transition-property: height; /* Safari */
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-property: height;
    transition-duration: 0.5s;

}
.issueprompt-enter, .issueprompt-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 50px;
}
.login {
  background-image: url('../assets/home-bg-min.jpg');
}
</style>