<template>
    <b-container fluid>
        <div class="login">
            <div class="login-gauss"></div>
        </div>
        <b-row align-h="center" class="login-tall-row">
            <b-col cols="12" md="8" lg="6" xl="4" align-self="center" class="login-box">
                <b-row align-v="center">
                    <b-col></b-col>
                    <b-col>
                        <b-img src="img/vai_full_stacked.png" fluid alt="ValveAI Logo" class="login-logo"></b-img>
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <hr>
                <b-row align-v="center" class="mt-3">
                    
                    <b-col cols="12" class="text-center">
                        <span class="h3">Forgotten Password Reset Request</span>
                    </b-col>
                </b-row>
                <hr>
                <b-row v-show="issueText != null" class="mb-3">
                    <b-col class="text-center">
                        <transition name="issueprompt">
                            <div class="slim">
                                {{issueText}}
                            </div>
                        </transition>
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col cols="12">
                        <b-form @submit="onSubmit">
                            <b-form-group
                                id="username-label"
                                label="Enter Your Email"
                                label-for="username"
                                :state="invalidLogin"
                            >
                                <b-form-input
                                    id="username"
                                    v-model="username"
                                    type="email"
                                    autocomplete="on"
                                    required
                                    :state="invalidLogin"
                                    trim
                                ></b-form-input>
                            </b-form-group>
                            <b-button block type="submit" variant="success" :disabled="issueText != null">
                                <b-spinner v-if="formWaiting" label="Spinning"></b-spinner>
                                <span v-if="!formWaiting">Request Password Reset</span>
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
const butils = require('../libs/basicUtils.js');

export default {
    name: 'forogtpassword',
    components:{

    },
    props:{

    },
    data(){
        return{
            emailRegex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            issueText: null,
            username: null,
            formWaiting: false,
        }
    },
    methods:{
        onSubmit(event){
            event.preventDefault();
            this.formWaiting = true;
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/auth/request_password_reset`, { username: this.username} )
            .then((response)=>{
               this.formWaiting = false; 
               this.issueText = "If An Account Exists Under This E-Mail, A Password Reset Link Has Been Sent."
            })
            .catch((err)=>{
                this.formWaiting = false; 
                console.log(err);
                butils.createToast(this, "Request Failed");
            })
        }
    },
    watch:{

    },
    computed:{
        invalidLogin(){
            if(this.username != null){
                if(this.username.match(this.emailRegex)){
                    return true;
                }else{
                    return false;
                }
            }else{
                return null;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
.login-logo{
    max-height: 30vh;
}
.login {
    position: absolute;
    height:100vh;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:right top;
    background-image: url('../assets/home-bg-min.jpg');
    background-attachment: fixed;
    margin: 0px;
    left:0px;
}
.login-gauss {
    position: absolute;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0px;
    left:0px;
}
.login-box {
    background-color: rgba(255, 255, 255, 0.40);
    border-radius: 1.5rem;
    padding-top: 1em;
    padding-bottom: 1.5em;
    color:rgba(0, 0, 0, 0.9);
}
.login-tall-row{
    height: 100vh;
}
input{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
input:focus{
    background-color: rgba(255, 255, 255, 0.60);
    color: black;
}
b-form-input:focus{
    background-color: rgba(255, 255, 255, 0.60);
}
.issueprompt-enter-active, .issueprompt-leave-active {
    -webkit-transition-property: height; /* Safari */
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-property: height;
    transition-duration: 0.5s;

}
.issueprompt-enter, .issueprompt-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 50px;
}
.login {
  background-image: url('../assets/home-bg-min.jpg');
}
</style>